import Vue from 'vue'
import Router from "vue-router";
// import HomeView from '../views/HomeView.vue'

Vue.use(Router)

function guardMyroute(to, from, next) {
  var isAuthenticated = false;
  if (localStorage.getItem('user'))
    isAuthenticated = true;
  else
    isAuthenticated = false;
  if (isAuthenticated) {
    next(); // allow to enter route
  }
  else {
    next('/'); // go to '/login';
  }
}

function checkRole(to, from, next) {
  var isAuthenticated = false;
  var user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    var userrole = user.rolename;
    // console.log("userrole == " + user.rolename);
    if (userrole == "SuperUser" || userrole == "Admin")
      isAuthenticated = true;
    else
      isAuthenticated = false;
    if (isAuthenticated) {
      next();
    }
    else {
      next('/my_subscriptions');
    }
  } else {
    next('/');
  }
}

export default new Router({
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
  //  mode: 'history',
  routes: [

    //Admin Routes start- Register-Login Pages 
    {
      path: "/",
      name: "login",
      meta: { layout: "userpages" },
      component: () => import("../Admin/UserPages/Login.vue")
    },
    {
      path: "/register_user",
      name: "register_user",
      meta: { layout: "userpages" },
      component: () => import("../Admin/UserPages/Register.vue")
    },
    {
      path: "/register",
      name: "register",
      meta: { layout: "userpages" },
      component: () => import("../Admin/UserPages/OtpValidation.vue")
    },
    {
      path: "/forgot_password",
      name: "forgot_password",
      meta: { layout: "userpages" },
      component: () => import("../Admin/UserPages/ForgotPassword.vue")
    },
    {
      path: '/reset_password_form',
      name: 'reset_password_form',
      component: () =>
        import('../Admin/UserPages/ResetPasswordForm.vue'),
      meta: { layout: 'userpages' },
    },
    {
      path: "/reset_password",
      name: "reset_password",
      beforeEnter: guardMyroute,
      component: () => import("../Admin/UserPages/ResetPassword.vue")
    },

    //After Login Pages

    //Dashboard
    {
      path: "/dashboard",
      name: "dashboard",
      beforeEnter: guardMyroute,
      component: () => import("../Admin/Pages/Dashboard.vue")
    },

    //Roles
    {
      path: "/roles",
      name: "roles",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Role/Index.vue"),
    },
    {
      path: "/roles_amend",
      name: "roles_amend",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Role/RolesAmend.vue"),
    },
    {
      path: "/roles_menu",
      name: "roles_menu",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Role/RoleMenu/MenuAccess")
    },

    //Menus
    {
      path: "/menus",
      name: "menus",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Menu/Index.vue"),
    },
    {
      path: "/menu_amend",
      name: "menu_amend",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Menu/MenusAmend.vue"),
    },

    //Lookups
    {
      path: "/lookups",
      name: "lookups",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Lookups/Index.vue"),
    },
    {
      path: "/lookups_amend",
      name: "lookups_amend",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Lookups/LookupsAmend.vue"),
    },
    {
      path: "/child_lookups",
      name: "child_lookups",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Lookups/ChildIndex.vue"),
    },
    //System parameters
    {
      path: "/system_parameter",
      name: "system_parameter",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/SystemParameter/Index.vue"),
    },
    {
      path: "/system_parameter_amend",
      name: "system_parameter_amend",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/SystemParameter/SystemParameterAmend.vue"),
    },
    //email templates
    {
      path: "/email_template",
      name: "email_template",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/EmailTemplates/Index.vue"),
    },
    {
      path: "/email_template_amend",
      name: "email_template_amend",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/EmailTemplates/EmailTemplateAmend.vue"),
    },

    //Users
    {
      path: "/users",
      name: "users",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Users/Index.vue")
    },
    {
      path: "/users.amend",
      name: "users.amend",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Users/UsersAmend.vue")
    },
    {
      path: "/users.view",
      name: "users.view",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Users/UserView.vue")
    },

    //countries
    {
      name: "countries",
      path: "/countries",
      beforeEnter: checkRole,
      component: () =>
        import(
          "../Admin/Pages/Configuration/Countries/CountriesIndex.vue"
        )
    },
    {
      name: "countries.amend",
      path: "/countries.amend",
      beforeEnter: checkRole,
      component: () =>
        import(
          "../Admin/Pages/Configuration/Countries/CountriesAmend.vue"
        )
    },
    {
      name: "states",
      path: "/states",
      beforeEnter: checkRole,
      component: () =>
        import(
          "../Admin/Pages/Configuration/Countries/StatesIndex.vue"
        )
    },
    {
      name: "states.amend",
      path: "/states.amend",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Countries/StatesAmend.vue")
    },
    {
      name: "cities",
      path: "/cities",
      beforeEnter: checkRole,
      component: () =>
        import(
          "../Admin/Pages/Configuration/Countries/CitiesIndex.vue"
        )
    },
    {
      name: "cities.amend",
      path: "/cities.amend",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Countries/CitiesAmend.vue")
    },

    //subscriptions
    {
      name: "make_subscriptions",
      path: "/make_subscriptions",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Subscription/Index.vue")
    },
    {
      name: "subscription.amend",
      path: "/subscription_amend",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Subscription/SubscriptionAmend.vue")
    },
    {
      name: "subscriptions",
      path: "/subscriptions",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Subscription/Subscriptions.vue")
    },
    {
      name: "subscription_checkout",
      path: "/subscription_checkout",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Subscription/SubscriptionCheckout.vue")
    },
    {
      name: "subscribed_list",
      path: "/subscribed_list",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Subscription/SubscribedList.vue")
    },
    {
      name: "my_subscriptions",
      path: "/my_subscriptions",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Subscription/MySubscription.vue")
    },
    {
      name: "payment_success",
      path: "/payment_success",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Subscription/PaymentSuccess.vue")
    },
    {
      name: "payment_fail",
      path: "/payment_fail",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/Subscription/PaymentFail.vue")
    },

    //Coupons
    {
      path: "/coupons",
      name: "coupons",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Coupons/Index.vue"),
    },
    {
      path: "/coupons_amend",
      name: "coupons_amend",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Coupons/Amend.vue"),
    },
    {
      path: "/checkout",
      name: "checkout",
      beforeEnter: guardMyroute,
      component: () =>
        import("../Admin/Pages/Configuration/OrderSummary/Index.vue"),
    },
    {
      path: "/user_authenticate",
      name: "user_authenticate",
      meta: { layout: "userpages" },
      component: () =>
        import("../Admin/Pages/Configuration/Authentication/Index.vue"),
    },
    //calculator
    {
      path: "/calculator",
      name: "calculator",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Calculator/Index.vue"),
    },
    {
      path: "/calculator_amend",
      name: "calculator_amend",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Calculator/Amend.vue"),
    },
    //FAQ
    {
      path: "/faq",
      name: "faq",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/FAQ/Index.vue"),
    },
    {
      path: "/faq_amend",
      name: "faq_amend",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/FAQ/FAQAmend.vue"),
    },
    // Marketing Opt-in
    {
      path: "/opt_in_marketing_users",
      name: "opt_in_marketing_users",
      beforeEnter: checkRole,
      component: () =>
        import("../Admin/Pages/Configuration/Marketing/Index.vue"),
    },

  ]
});



