<template>
  <div id="app">
    <component :is="layout">
     <FlashMessage :position="'right bottom'" style="position:fixed;z-index:15000"></FlashMessage>
      <transition name="scale" mode="out-in">
        <router-view></router-view>
      </transition>
    </component>
  </div>
</template>

<script>
const default_layout = "default";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    }
  }
};
</script>

<style lang="scss">
// @import "~@fullcalendar/core/main.css";
// @import "~@fullcalendar/daygrid/main.css";
// @import "~@fullcalendar/timegrid/main.css";
@import "assets/base.scss";
@import "assets/global.css";
</style>
<style lang="css">
.scale-enter-active,
.scale-leave-active {
  transition: all 0.6s ease !important;
}
.scale-enter-from,
.scale-leave-to {
  opacity: 0 !important;
  transform: scale(0.9) !important;
}
</style>
