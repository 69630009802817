<template>
  <div class="d-flex">
    <div class="header-btn-lg pr-0">
      <div class="widget-content p-0">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <b-dropdown
              toggle-class="p-0 mr-2"
              menu-class="dropdown-menu-lg dropdown-menu"
              variant="link"
              no-caret
              right
            >
              <span slot="button-content">
                <div
                  class="icon-wrapper icon-wrapper-alt rounded-circle d-flex justify-content-center"
                >
                  <img
                    v-if="user && user.image_url != null"
                    width="42"
                    height="42"
                    class="rounded-circle"
                    :src="envImagePath + user.image_url"
                    alt
                  />
                  <img
                    v-else
                    width="42"
                    class="rounded-circle"
                    src="@/assets/images/default-user-profile-picture.png"
                    alt
                  />
                </div>
              </span>
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner">
                  <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <img
                            v-if="user && user.image_url != null"
                            width="42"
                            height="42"
                            class="rounded-circle"
                            :src="envImagePath + user.image_url"
                            alt
                          />
                          <img
                            v-else
                            width="42"
                            class="rounded-circle"
                            src="@/assets/images/default-user-profile-picture.png"
                            alt
                          />
                        </div>
                        <div v-if="user" class="widget-content-left">
                          <div class="widget-heading user-name">
                            {{ user.name }} {{ user.lastname }}
                          </div>
                          <div class="widget-subheading opacity-8">
                            {{ user.description }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="scroll-area-xs">
                <VuePerfectScrollbar class="scrollbar-container" v-once>
                  <ul class="nav flex-column px-5">
                    <li v-if="user" class="nav-item d-flex">
                      <v-icon class="icon_color delete_btn"
                        >mdi-account-box</v-icon
                      >
                      <router-link
                        :to="{
                          name: 'users.view',
                          query: {
                            slug: user.slug,
                            view: 'myprofile',
                          },
                        }"
                        class="nav-link"
                        >{{ $t("myprofile") }}</router-link
                      >
                    </li>
                    <li class="nav-item d-flex">
                      <v-icon class="icon_color delete_btn">mdi-restore</v-icon>
                      <router-link
                        :to="{ name: 'reset_password' }"
                        class="nav-link"
                        >{{ $t("reset_password") }}</router-link
                      >
                    </li>
                    <li class="nav-item d-flex">
                      <v-icon class="icon_color edit_btn">mdi-logout</v-icon>
                      <div class>
                        <button class="nav-link" @click="logout">
                          {{ $t("logout") }}
                        </button>
                      </div>
                    </li>
                  </ul>
                </VuePerfectScrollbar>
              </div>
            </b-dropdown>
          </div>
          <div v-if="user" class="widget-content-left user-data">
            <div class="widget-heading">
              {{ user.name }} {{ user.lastname }}
            </div>
            <div class="widget-subheading" v-if="user.role">
              {{ user.role.rolename }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

import { mapGetters, mapActions } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faCalendarAlt,
  faTrashAlt,
  faCheck,
  faFileAlt,
  faCloudDownloadAlt,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAngleDown,
  faCalendarAlt,
  faTrashAlt,
  faCheck,
  faFileAlt,
  faCloudDownloadAlt,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faEllipsisH
);

export default {
  components: {
    VuePerfectScrollbar,
  },
  data: () => ({
    fill1: { gradient: ["#00b09b", "#96c93d"] },
    fill2: { gradient: ["#ff0844", "#ffb199"] },
    fill3: { gradient: ["#f6d365", "#fda085"] },
    showDrawerSection: false,
    user: "",
    envImagePath: process.env.VUE_APP_IMAGE_PATH,
  }),
  computed: {
    ...mapGetters(["auth"]),
  },
  mounted() {
    this.$eventBus.$on("update_myprofile", this.updateUser);
  },
  created() {
    this.updateUser();
  },
  methods: {
    ...mapActions("auth", ["sendLogoutRequest"]),
    logout() {
      this.$router.push("/");
      localStorage.removeItem("user");
    },
    updateUser() {
      this.user = JSON.parse(localStorage.getItem("user"));
    },
  },
};
</script>
<style scoped>
.widget-content-left >>> .dropdown-menu-lg {
  min-width: 20rem !important;
}

.width-500 {
  width: 500px !important;
}

.icon_color {
  color: #060895 !important;
}

.router-link-exact-active {
  background: #ffffff !important;
  font-weight: bolder !important;
}
@media (max-width: 800px) {
  .user-data {
    display: none !important;
  }
  .dropdown >>> .dropdown-menu{
    left: 20% !important;
  }
}
.dropdown-menu-header-inner{
  background: linear-gradient(0deg, #060895, #5586fb);
}
.nav-link{
  color: #060895;
}
.router-link-exact-active{
  color: #010369 !important;
  text-shadow: 0px 1px 1px blue;
}
.user-name{
  color: white;
  text-shadow: 2px 4px 2px black;
}
</style>