import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=21e1787a&scoped=true"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=21e1787a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e1787a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
