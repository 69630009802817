<template>
  <div :class="headerbg" class="app-header header-shadow">
    <div class="logo-src" />

    <b-dropdown variant="link" no-caret class="ml-2">
      <span slot="button-content">
        <v-icon>mdi-menu</v-icon>
      </span>

      <div class="scroll-area-xs">
        <h6 tabindex="-1" class="dropdown-header">Menus</h6>
        <div v-for="(menu, i) in menus" :key="i">
          <button
            type="button"
            :tabindex="i"
            class="dropdown-item"
            v-bind:style="selected == i ? 'background:#e9ecef' : ''"
            @click="routeMenu(i, menu.href)"
          >
            <span :class="menu.icon" style="width: 25px"></span>{{ menu.title }}
          </button>
        </div>
      </div>
    </b-dropdown>
    <div class="app-header__logo app-banner">
      <div v-if="app_image_url">
        <a>
          <img
            class="custom-logo"
            v-bind:src="app_image_url"
            style="width: 10vh; transition: width 0.5s"
          />
        </a>
      </div>
      <div v-else-if="app_image_url == ''">
        <a class="font-base-app text-center" style="font-size: 26px">
          {{ application_name }}
        </a>
      </div>
      <div v-else>
        <a class="font-base-app text-center" style="font-size: 26px">
          {{ application_name }}
        </a>
      </div>
    </div>
    <div class="app-header__content">
      <div class="app-header-right">
        <UserArea />
      </div>
    </div>
    <!-- <div class="app-header__mobile-menu">
      <div> -->
  </div>
</template>

<script>
import UserArea from "./Header/HeaderUserArea";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faEllipsisV);
export default {
  name: "Header",
  components: {
    UserArea,
    "font-awesome-icon": FontAwesomeIcon,
  },

  data() {
    return {
      isOpen: false,
      isOpenMobileMenu: false,
      app_image_url: "",
      parameter_image_url: [],
      application_name: "",
      showMobileMenu: false,
      menus: [],
      selected: null,
    };
  },
  props: {
    headerbg: String,
  },
  mounted() {
    this.$eventBus.$on("app_logo", this.change_logo);
    this.fetchAppImageUrl();
    this.fetchMenu();
  },
  methods: {
    routeMenu(i, path) {
      this.selected = i;
      this.$router.push({
        name: path,
      });
    },
    fetchMenu() {
      this.user = JSON.parse(localStorage.getItem("user"));

      if (this.user) {
        axios
          .get(
            process.env.VUE_APP_API_URL_ADMIN +
              "menutree?+&role=" +
              this.user.role_id
          )
          .then((res) => {
            if (Array.isArray(res.data.message)) {
              this.array_data = res.data.message.toString();
            } else {
              this.array_data = res.data.message;
            }
            if (res.data.status == "S") {
              // console.log(res.data);
              this.menus = res.data.menu;
            } else if (res.data.status == "E") {
              this.flashMessage.error({
                message: this.array_data,
                time: 4000,
                blockClass: "custom-block-class",
              });
            } else {
              this.flashMessage.error({
                message: this.array_data,
                time: 4000,
                blockClass: "custom-block-class",
              });
            }
          })
          .catch((err) => {
            this.flashMessage.error({
              message: this.$t("something_went_wrong"),
              time: 4000,
              blockClass: "custom-block-class",
            });
            console.log(err);
          });
      }
    },
    toggleMobile(className) {
      this.showMobileMenu = !this.showMobileMenu;
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },

    // toggleMobile2(className) {
    //   const el = document.body;
    //   this.isOpenMobileMenu = !this.isOpenMobileMenu;

    //   if (this.isOpenMobileMenu) {
    //     el.classList.add(className);
    //   } else {
    //     el.classList.remove(className);
    //   }
    // },
    change_logo() {
      this.app_image_url = localStorage.getItem("App_Image_Url");
      this.fetchAppImageUrl();
    },
    fetchAppImageUrl() {
      axios
        .get(process.env.VUE_APP_API_URL_ADMIN + "fetch_image_url", {})
        .then((res) => {
          this.parameter_image_url = res.data.parameter_image;
          this.application_name = res.data.application_name;
          localStorage.setItem("Application_Name", this.application_name);

          if (this.parameter_image_url != null) {
            localStorage.setItem(
              "App_Image_Url",
              this.parameter_image_url.image_full_url
            );
            this.app_image_url = localStorage.getItem("App_Image_Url");
          } else {
            localStorage.removeItem("App_Image_Url");
            this.app_image_url = "";
          }
        })
        .catch((err) => {
          this.flashMessage.error({
            message: this.$t("something_went_wrong"),
            time: 4000,
            blockClass: "custom-block-class",
          });
          console.log("this error" + err);
        });
    },
  },
};
</script>
<style scoped>
/* @media(max-width:800px){
  .menu-icon{
    
  }
} */
@media (min-width: 801px) {
  .menu-icon,
  .app-banner {
    display: none;
  }
}

.dropdown >>> .dropdown-menu {
  margin-top: 12px !important;
  margin-left: 0px !important;
  width: 96vw;
}
.custom-logo {
  width: 8vh !important;
}
@media (max-width: 800px) {
  .app-header__content {
    padding-left: 0px !important;
  }
}
</style>
